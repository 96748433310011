import { Helmet } from 'react-helmet';
import React, {useEffect, useState} from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function ViewAllResults() {
    const [data, setData] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/admin/get-result`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchData();
    }, []);

    const deleteNotification = async (id, e) => {
        e.preventDefault();
        setIsDeleting(true);
        try {
            await axios.delete(`https://cms-back-production.up.railway.app/api/admin/delete-result?id=${id}`);
            setData(prevData => prevData.filter(item => item._id !== id));
        } catch (error) {
            alert('Error!');
        } finally {
            setIsDeleting(false);
        }
    };
    

    return (
        <>
            <Sidebar/>
            <AdminNav/>
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isDeleting && <div className="loading-overlay"><LoadingSpinner /></div>}
                <div className={`viewtable ${isDeleting ? 'blur' : ''}`}>
                    <div className="pagehead"><span>All Results</span></div>
                    {data?.map((item, index) => (
                        <div className="viewcard" key={index}>
                            <div className="item">
                                <span className="head">Student Name</span>
                                <span className="content">{item.studentName}</span>
                            </div>
                            <div className="item">
                                <span className="head">Register Number</span>
                                <span className="content">{item.registerNumber}</span>
                            </div>
                            <div className="buttons">
                                <button className='actionbutton delete' onClick={(e) => deleteNotification(item._id, e)}>Delete</button>
                            </div>
                        </div>
                    ))}  
                </div>
            </div>
            <AdminFooter/>
        </>
    );
}

export default ViewAllResults;
