import React, { useEffect, useState } from 'react';
import QuickEnquiry from '../innerComponents/QuickEnquiry';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import img from '../assets/courses/image4.avif';

function AllCources({courses,category}) {
    const [activeCategory, setActiveCategory] = useState('all');
    useEffect(()=>{
        setActiveCategory(category);
    },[category])
    return (
        category==='all'?
        <>
            <Helmet>
                <title>NSF COURSES</title>
            </Helmet>
            <div>
                <div className="coursecategories">
                    <Link to='/courses' className={(activeCategory==='all')?"catbutton active":"catbutton"}>All</Link>
                    <Link to='/courses/diploma-courses'  className={(activeCategory==='diploma-courses')?"catbutton active":"catbutton"}>Diploma Courses</Link>
                    <Link to='/courses/pg-diploma-courses'  className={(activeCategory==='pg-diploma-courses')?"catbutton active":"catbutton"}>PG Diploma Courses</Link>
                    <Link to='/courses/others'  className={(activeCategory==='others')?"catbutton active":"catbutton"}>Other Courses</Link>
                </div>
                <div className='courcescard'>
                    {courses?.map((item, index) => (
                        <Link className="ccard" to={item.route} key={index}>
                            <div className="cimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="ctext">
                            {item.coursename}
                            </div>
                        </Link>
                    ))}
                </div>
                <QuickEnquiry/>
            </div>
        </>
        :
        <>
            <Helmet>
                <title>NSF COURSES</title>
            </Helmet>
            <div>
                <div className="coursecategories">
                    <Link to='/courses'  className={(activeCategory==='all')?"catbutton active":"catbutton"}>All</Link>
                    <Link to='/courses/diploma-courses'  className={(activeCategory==='diploma-courses')?"catbutton active":"catbutton"}>Diploma Courses</Link>
                    <Link to='/courses/pg-diploma-courses'  className={(activeCategory==='pg-diploma-courses')?"catbutton active":"catbutton"}>PG Diploma Courses</Link>
                    <Link to='/courses/others'  className={(activeCategory==='others')?"catbutton active":"catbutton"}>Other Courses</Link>
                </div>
                <div className='courcescard'>
                    {courses?.map((item, index) => (
                        item.category===category?
                        <Link className="ccard" to={item.route} key={index}>
                            <div className="cimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="ctext">
                            {item.coursename}
                            </div>
                        </Link>
                        :
                        <></>
                    ))}
                </div>
                <QuickEnquiry/> 
            </div>  
        </>    
    )
}

export default AllCources;