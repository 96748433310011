// export const baseUrl = 'http://localhost:3000/api'
export const baseUrl = 'https://cms-back-production.up.railway.app/api'
























// import img from '../assets/courses/image4.avif';
// import fac1 from '../assets/facs/fac1.jpeg';
// import fac2 from '../assets/facs/fac2.jpeg';
// import fac3 from '../assets/facs/fac3.jpeg';
// import fac4 from '../assets/facs/fac4.jpeg';
// import news1 from '../assets/news/news1.jpeg';
// import news2 from '../assets/news/news2.jpeg';
// import news3 from '../assets/news/news3.jpeg';
// import news4 from '../assets/news/news4.jpeg';
// import img1 from '../assets/testimonials/si1.png';
// import img2 from '../assets/testimonials/si2.png';
// import img3 from '../assets/testimonials/si3.jpeg';
// export const courses=[
//     {
//         image:img,
//         name:"PG Diploma in Logistics & Supply chain management",
//         sroute:"pg-diploma-in-logistics-and-supply-chain-management",
//         route:"/courses/pg-diploma-courses/pg-diploma-in-logistics-and-supply-chain-management",
//         description:"The importance of logistics and supply chain management has become a critical skill in this fast-evolving world of business. The program we provide will teach our students the knowledge and skills to drive forward change in the management of the global supply chain and make a significant difference in the global marketplace. Our students will complete the course with all the tools required for a career in India or any abroad country.",
//         category:"pg-diploma-courses",
//     },
//     {
//         image:img,
//         name:"PG Diploma in English Language Teaching",
//         sroute:"pg-diploma-in-english-language-teaching",
//         route:"/courses/pg-diploma-courses/pg-diploma-in-english-language-teaching",
//         description:"The language English and the prospects of an English teacher are never-ending. For someone who aspires to be a teacher, this course will get them high-paying jobs and faster promotions as we impart practical pedagogical and leadership strategies along with teaching our students a world-class syllabus that will make them an expert educator.",
//         category:"pg-diploma-courses",
//     },
//     {
//         image:img,
//         name:"PG Diploma in Hotel Management",
//         sroute:"pg-diploma-in-hotel-management",
//         route:"/courses/pg-diploma-courses/pg-diploma-in-hotel-management",
//         description:"In the post-pandemic world, the global hotel industry and hospitality industry is revolutionized. There is a significant increase in demand for new experiences, innovative thinking, and digital leadership, we aim to narrow the existing gap between the demand and supply of skills needed for this industry through our course. Our academic expertise and impressive network of industry connections will help our students build successful careers.",
//         category:"pg-diploma-courses",
//     },
//     {
//         image:img,
//         name:"CMA INDIA INTERMIDIATE Cost and management accountant",
//         sroute:"cma",
//         route:"/courses/other-courses/cma",
//         description:"CMA or Cost and Management Accounting course is an esteemed qualification that will clear a path towards the high paying jobs as accountant. Students who aspires to have a career in Accounting and finance is the right fit for this course as they will get the best classes, skill building training from NSF.",
//         category:"other-courses",
//     },
//     {
//         image:img,
//         name:"3 Months Medical coding & Billing course",
//         sroute:"medical-coding",
//         route:"/courses/other-courses/medical-coding",
//         description:"This course is an innovative, career- orientated program that responds to the evolving Health care industry. This course will equip our students with a comprehensive understanding of coding languages. Our students will also be trained to achieve skills to handle real- world medical billing scenarios professionally.",
//         category:"other-courses",
//     },
//     {
//         image:img,
//         name:"1 year  diploma in EV service technician, EV assembly operator, EV charging station technician",
//         sroute:"diploma-in-ev-service",
//         route:"/courses/diploma-courses/diploma-in-ev-service",
//         description:"Electric vehicles are the future. Securing a job in the EV industry is equal to securing your tomorrow. This course is a gateway into the electrifying world of opportunity and immense potential. This course will teach our students the essential principles, techniques, and technologies behind EVs.",
//         category:"diploma-courses",
//     },
// ]

// export const facs=[
//     {
//         img:fac1,
//         name:'FABI RAHMAN A P',
//         designation:'Skill Development Executive',
//     },
//     {
//         img:fac2,
//         name:'ASHIKA T',
//         designation:'Program Executive of Logistics Management',
//     },
//     {
//         img:fac3,
//         name:'GRACE V PHILIP',
//         designation:'Program Executive of ELT',
//     },
//     {
//         img:fac4,
//         name:'SERNAM MOHAMMED',
//         designation:'Program Manager',
//     },
// ]

// export const news = [
//     {
//         img: news1,
//         text: 'New Admission Started for Diploma/PG Diploma in Hybrid Airline & Airport Management',
//         date: '2024-05-23',
//     },
//     {
//         img: news2,
//         text: 'New Admission Started for CMA India Intermediate Course',
//         date: '2024-05-23',
//     },
//     {
//         img: news3,
//         text: 'New Admission Started for 1 Year PG Diploma in Logistics and Supply Chain Management',
//         date: '2024-05-23',
//     },
//     {
//         img: news4,
//         text: 'New Admission Started for PG Diploma in English Language Teaching',
//         date: '2024-05-23',
//     }
// ];
// export const testimonials = [
//     {
//         name:'MUHAMMED SINAN P',
//         course:'PG Diploma in Logistic Management 2023-2024 Batch',
//         text:'My time at NSF has been incredibly rewarding. The knowledgeable faculty and their willingness to help have been crucial in my academic journey. The high-quality resources and modern facilities available at NSF create an ideal setting for education and personal development, making it an excellent choice for any student.',
//         image:img3
//     },
//     {
//         name:'NAVYA K V',
//         course:'PG Diploma in Logistic Management 2023-2024 Batch',
//         text:'Attending NSF has been one of the best decisions of my academic career. The faculty is incredibly knowledgeable and always willing to help. The resources and facilities are top-notch, providing an ideal environment for learning and personal growth.',
//         image:img1
//     },
//     {
//         name:'HASEEB P',
//         course:'PG Diploma in Logistic Management 2023-2024 Batch',
//         text:'NSF has exceeded my expectations in every way. The professors are incredibly supportive and approachable, always eager to provide guidance and share their extensive knowledge. The campus facilities are modern and well-maintained, making NSF the perfect place for both academic pursuits and personal development.',
//         image:img2
//     },
//   ];