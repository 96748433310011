import React, { useState, useEffect } from 'react';
import '../styles/HomeBannerNew.css';
// import banner1 from '../assets/banners/banner2.jpg';
// import banner2 from '../assets/ill1.webp'
// import { MdArrowBack,MdArrowForward } from 'react-icons/md';


const HomeBannerNew = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  useEffect(() => {
    console.log(props.banners[0].imgUrl);
    
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % props.banners.length);
      setPlayAnimation(true); 
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1000);
    };
    const timerId = setInterval(changeImage, 5000);
    return () => clearInterval(timerId);
  }, [props.banners]);

  // const goToPreviousImage = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex === 0 ? props.banners.length - 1 : prevIndex - 1
  //   );
  //   setPlayAnimation(true); 
  //     setTimeout(() => {
  //       setPlayAnimation(false);
  //     }, 1000);
  // };

  // const goToNextImage = () => {
  //   setCurrentImageIndex((prevIndex) => 
  //       (prevIndex + 1) % props.banners.length
  //   );
  //   setPlayAnimation(true); 
  //     setTimeout(() => {
  //       setPlayAnimation(false);
  //     }, 1000);
  // };

  return (
    <div className="homenew-banner">
      <img src={props.banners[currentImageIndex].imgUrl} alt={`Banner ${currentImageIndex}`} className={playAnimation ? 'fade-in-animation' : ''}/>
    </div>
  );
};

export default HomeBannerNew;

