import React, { useEffect, useRef } from 'react';
import '../styles/LogoSlider.css';
import img1 from '../assets/sliderlogos/logo1.png';
import img2 from '../assets/sliderlogos/logo2.png';
import img3 from '../assets/sliderlogos/logo3.png';
import img4 from '../assets/sliderlogos/logo4.png';
import img5 from '../assets/sliderlogos/logo5.png';
import img6 from '../assets/sliderlogos/logo6.png';
import img7 from '../assets/sliderlogos/logo7.png';
import img8 from '../assets/sliderlogos/logo8.png';
import img9 from '../assets/sliderlogos/logo9.png';
import img10 from '../assets/sliderlogos/logo10.png';
const LogoSlider = () => {
  const logosRef = useRef(null);

  useEffect(() => {
    const copy = logosRef.current.cloneNode(true);
    logosRef.current.parentNode.appendChild(copy);
  }, []);

  return (
    <div className="sliderlogos">
      <div ref={logosRef} className="sliderlogos-slide s2">
        <div className="logo-img s2"><img src={img1} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img2} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img3} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img4} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img5} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img6} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img7} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img8} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img9} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img10} alt="logo1" /></div>
        <div className="space"></div>
      </div>
    </div>
  );
};

export default LogoSlider;

