import React from "react";
import '../styles/notifications.css';
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LoadingSpinner from '../innerComponents/LoadingSpinner';

function Circulars(props) {
  const { contacts, notifications } = props;
  const circularNotifications = notifications?.filter(notification => notification.category.toLowerCase() === 'circular');

  return (
    <>
      {contacts && notifications ? (
        <>
          <Helmet>
            <title>Circulars - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Circulars</span>
          </div>
          {circularNotifications?.length <= 0 ? (
            <div className="nonotifications">
              No Notifications!
            </div>
          ) : (
            <>
              {circularNotifications.map((item, index) => (
                <div className='notificationsmain' key={index}>
                  <span className='head'>{item.title}</span>
                  <div className='dateandtype'>
                    <span className='date'>{item.date}</span>
                    <div className="vert"></div>
                    <span className='type'>{item.category.toUpperCase()}</span>
                  </div>
                  <span className='content'>{item.description}</span>
                </div>
              ))}
            </>
          )}
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
            <Helmet>
                <title>Circulars - NSF</title>
            </Helmet>
            <NavBar/>
            <ApplyButton />
            <ScrollTopButton />
            <div className='headpage'>
                <span>Circulars</span>
            </div>
            <LoadingSpinner />
            <Footer/>
        </>
      )}
    </>
  );
}

export default Circulars;
