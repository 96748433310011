import React, { useState } from 'react';
import '../styles/Nav.css';
import logo from '../assets/logo.png';
import { MdArrowDropDown, MdPhone } from 'react-icons/md';
import { FaFacebookF, FaInstagram } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { FaGlobeAsia, FaTimes } from 'react-icons/fa';

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="mynewnav">
      <div className="logosec">
        <Link to='/'><img src={logo} alt="logo" /></Link>
      </div> 
      <div className={`linksec ${isOpen ? 'open' : ''}`}>
        <div className="closebutton" onClick={handleToggle}>
          <span><FaTimes/></span>
        </div>
        <div className='links'>
          <div onClick={handleToggle}><Link className='link' to='/about'>About</Link></div>
          <div className="horizotalline"></div>
          <div onClick={handleToggle}><Link className='link' to='/courses'>Courses</Link></div>
          <div className="horizotalline"></div>
          <div onClick={handleToggle}><Link className='link' to='/news'>News</Link></div>
          <div className="horizotalline"></div>
          <div onClick={handleToggle}><Link className='link' to='/team'>Team</Link></div>
          <div className="horizotalline"></div>
          <div onClick={handleToggle}><Link className='link' to='/contact'>Contact</Link></div>
          <div className="horizotalline"></div>
          <div className='dropdownmain' onClick={toggleDropdown}>
            <div><Link className='link'>Notifications<MdArrowDropDown/></Link></div>
            <div className={`dropdown ${dropdownOpen ? 'show' : ''}`}>
              <Link to='/notifications' onClick={handleToggle} className='link'>All Latest</Link>
              <Link to='/circulars' onClick={handleToggle} className='link'>Circulars</Link>
              <Link to='/examinations' onClick={handleToggle} className='link'>Examinations</Link>
              <Link to='/events' onClick={handleToggle} className='link'>Events</Link>
            </div>
          </div>
          <div className="horizotalline"></div>
          <div onClick={handleToggle}><Link to='/student' className='link'>Student Portal</Link></div>
          <div className="horizotalline"></div>
        </div>
      </div>
      <div className="connectsec">
        <div className="mytogglebutton" onClick={handleToggle}>
          <span>☰</span>
        </div>
        {props.contacts ? (
        <div className='sociallinks'>
          <Link href={`tel:+91${props.contacts?.mobile2}`}><div className='phone'><MdPhone/></div></Link>
          <div className='num'>
            <div className="callus">Call Us</div>
            <div className="dig">+91{props.contacts?.mobile2}</div>
          </div>
          <Link to={props.contacts?.instagram}><div className='insta'><FaInstagram/></div></Link>
          <Link to={props.contacts?.facebook}><div className='fb'><FaFacebookF/></div></Link>
          <Link to='/admin/login'><div className='admin'><FaGlobeAsia/></div></Link>
        </div>):(<></>)}
      </div> 
    </div>
  );
};

export default NavBar;
