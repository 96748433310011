import React from "react";
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LoadingSpinner from '../innerComponents/LoadingSpinner';
import '../styles/notifications.css';

function Examinations(props) {
  const { contacts, notifications } = props;
  const examinationNotifications = notifications?.filter(notification => notification.category.toLowerCase() === 'examinations');

  return (
    <>
      {contacts && notifications ? (
        <>
          <Helmet>
            <title>Examinations - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Examinations</span>
          </div>
          {examinationNotifications?.length <= 0 ? (
            <div className="nonotifications">
              No Notifications!
            </div>
          ) : (
            <>
              {examinationNotifications.map((item, index) => (
                <div className='notificationsmain' key={index}>
                  <span className='head'>{item.title}</span>
                  <div className='dateandtype'>
                    <span className='date'>{item.date}</span>
                    <div className="vert"></div>
                    <span className='type'>{item.category.toUpperCase()}</span>
                  </div>
                  <span className='content'>{item.description}</span>
                </div>
              ))}
            </>
          )}
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>Examinations - NSF</title>
          </Helmet>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Examinations</span>
          </div>
          <LoadingSpinner />
          <Footer/>
        </>
      )}
    </>
  );
}

export default Examinations;
