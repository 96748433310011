import '../styles/App.css';
import React, { useEffect, useState } from "react";
import Home from './Home';
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from '../innerComponents/ScrollToTop';
import About from './About';
import Cources from './Cources';
import Contact from './Contact';
import News from './News';
import AllCources from '../innerComponents/AllCourses';
import CourseDetails from '../innerComponents/CourseDetails';
import LoginAdmin from '../pages/admin/LoginAdmin';
import HomeAdmin from '../pages/admin/HomeAdmin';
import Notifications from './Notification';
import Circulars from './Circular';
import Examinations from './Examination';
import Events from './Events';
import Student from './Student';
import Faculties from './Faculty';
import AddCourse from './admin/Course/AddCourse';
import { AuthProvider } from '../pages/admin/AuthContext';
import ProtectedRoute from '../pages/admin/ProtectedRoute';
import ViewCourses from './admin/Course/ViewCourses';
import EditCourse from './admin/Course/EditCourse';
import AddTeamMember from './admin/Team/AddTeam';
import ViewTeam from './admin/Team/ViewTeam';
import EditTeamMember from './admin/Team/EditTeam';
import ViewTestimonials from './admin/Testimonials/ViewTestimonial';
import AddTestimonial from './admin/Testimonials/AddTestimonial';
import EditTestimonial from './admin/Testimonials/EditTestimonial';
import EditContacts from './admin/EditContacts';
import EditCounts from './admin/EditCounts';
import EditAboutPage from './admin/EditAboutPage';
import AddNews from './admin/News/AddNews';
import ViewNews from './admin/News/ViewNews';
import EditNews from './admin/News/EditNews';
import AddNotification from './admin/Notifications/AddNotification';
import ViewNotifications from './admin/Notifications/ViewNotifications';
import EditNotification from './admin/Notifications/EditNotification';
import axios from 'axios';
import AddResult from './admin/StudentResult/AddResult';
import ViewAllResults from './admin/StudentResult/ViewResult';
import Error from './Error';
import { baseUrl } from '../data/data';
import '../styles/Home.css';
import LoadingSpinner from '../innerComponents/LoadingSpinner';
import AddBanner from './admin/Banner/AddBanner';
import ViewBanner from './admin/Banner/ViewBanner';

function App() {
  const [course, setcourse] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [team, setTeam] = useState([]);
  const [news, setNews] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [about, setAbout] = useState({});
  const [counts, setcounts] = useState({});
  const [contacts, setcontacts] = useState({});
  const [dashboard, setdashboard] = useState({});
  const [banners, setBanners] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchcourse = async () => {
      setLoading(true);
      try {
        const courseResponse = await axios.get(`${baseUrl}/admin/get-course`);
        const aboutResponse = await axios.get(`${baseUrl}/admin/get-about-page`);
        const notificationResponse = await axios.get(`${baseUrl}/admin/get-notification`);
        const teamResponse = await axios.get(`${baseUrl}/admin/get-faculty`);
        const contactResponse = await axios.get(`${baseUrl}/admin/get-contact-details`);
        const countResponse = await axios.get(`${baseUrl}/admin/get-counts`);
        const testimonialResponse = await axios.get(`${baseUrl}/admin/get-testimonial`);
        const newsResponse = await axios.get(`${baseUrl}/admin/get-news`);
        const dashboardResponse =  await axios.get(`${baseUrl}/admin/get-dashboard-count`);
        const bannerResponse =  await axios.get(`${baseUrl}/admin/get-banner`);
        setcourse(courseResponse.data);
        setAbout(aboutResponse.data);
        setNotifications(notificationResponse.data);
        setTeam(teamResponse.data.images);
        setNews(newsResponse.data.images);
        setcontacts(contactResponse.data);
        setcounts(countResponse.data);
        setTestimonials(testimonialResponse.data.images);
        setdashboard(dashboardResponse.data);
        setBanners(bannerResponse.data.images);

        setLoading(false);
      } catch (error) {
        alert(error);
        setLoading(false);
      }
    };

    fetchcourse();
  }, []);

  if (loading) {
    return (
      <AuthProvider>
      <ScrollToTop/>
      <AnimatePresence>
        <Routes>
          <Route path="*" element={<Error/>} />
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/team' element={<Faculties/>}/>
          <Route path="/courses" element={<Cources/>}>
            <Route path="" element={<AllCources/>} />
          </Route>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/notifications' element={<Notifications/>}/> 
          <Route path='/circulars' element={<Circulars/>}/> 
          <Route path='/examinations' element={<Examinations/>}/> 
          <Route path='/events' element={<Events/>}/> 
          <Route path='/student' element={<Student/>}/>
          <Route path='/admin/login' element={<LoginAdmin/>} />
          <Route path='/admin/*' element={<ProtectedRoute />}>
            <Route path='' element={<><LoadingSpinner/></>} />
          </Route>
        </Routes>
      </AnimatePresence>
    </AuthProvider>
    )
  }

  return (
    <AuthProvider>
      <ScrollToTop/>
      <AnimatePresence>
        <Routes>
          <Route path="*" element={<Error/>} />
          <Route path='/' element={<Home about={about} testimonials={testimonials} news={news} counts={counts} contacts={contacts} banners={banners}/>}/>
          <Route path='/about' element={<About about={about} counts={counts} contacts={contacts}/>}/>
          <Route path='/team' element={<Faculties team={team} counts={counts} contacts={contacts}/>}/>
          <Route path="/courses" element={<Cources courses={course} counts={counts} contacts={contacts}/>}>
            <Route path="" element={<AllCources courses={course} category="all" counts={counts} contacts={contacts}/>} />
            {course.map((item, index) => (
              <Route key={index} path={item.category} element={<AllCources courses={course} category={item.category} counts={counts} contacts={contacts}/>} />
            ))}
            {course.map((item, index) => (
              <Route key={index} path={`${item.category}/${item.sroute}`} element={<CourseDetails course={item} courses={course} />} />
            ))}
          </Route>
          <Route path='/contact' element={<Contact counts={counts} contacts={contacts} />}/>
          <Route path='/news' element={<News news={news} counts={counts} contacts={contacts}/>}/>
          <Route path='/notifications' element={<Notifications notifications={notifications} counts={counts} contacts={contacts}/>}/> 
          <Route path='/circulars' element={<Circulars notifications={notifications} counts={counts} contacts={contacts}/>}/> 
          <Route path='/examinations' element={<Examinations notifications={notifications} counts={counts} contacts={contacts}/>}/> 
          <Route path='/events' element={<Events notifications={notifications} counts={counts} contacts={contacts}/>}/> 
          <Route path='/student' element={<Student/>}/>

          <Route path='/admin/login' element={<LoginAdmin />} />
          <Route path='/admin/*' element={<ProtectedRoute />}>
            <Route path='' element={<HomeAdmin dashboard={dashboard}/>} />

            <Route path='add-banner' element={<AddBanner />} />
            <Route path='view-banner' element={<ViewBanner team={team}/>} />

            <Route path='add-course' element={<AddCourse />} />
            <Route path='view-courses' element={<ViewCourses courses={course}/>} />
            <Route path='edit-course/:id' element={<EditCourse/>}/>

            <Route path='add-team-member' element={<AddTeamMember/>}></Route>
            <Route path='view-team' element={<ViewTeam team={team}/>}></Route>
            <Route path='edit-team-member/:id/:img' element={<EditTeamMember/>}></Route>

            <Route path='add-testimonial' element={<AddTestimonial/>}></Route>
            <Route path='view-testimonials' element={<ViewTestimonials testimonials={testimonials}/>}></Route>
            <Route path='edit-testimonial/:id/:img' element={<EditTestimonial/>}></Route>

            <Route path='add-news' element={<AddNews/>}></Route>
            <Route path='view-news' element={<ViewNews news={news}/>}></Route>
            <Route path='edit-news/:id/:img' element={<EditNews/>}></Route>

            <Route path='add-result' element={<AddResult/>}></Route>
            <Route path='view-result' element={<ViewAllResults/>}></Route>
            <Route path='edit-result/:id/:doc' element></Route>

            <Route path='add-notification' element={<AddNotification/>}></Route>
            <Route path='view-notifications' element={<ViewNotifications notifications={notifications}/>}></Route>
            <Route path='edit-notification/:id' element={<EditNotification/>}></Route>

            <Route path='edit-contacts' element={<EditContacts/>}></Route>
            <Route path='edit-counts' element={<EditCounts/>}></Route>
            <Route path='edit-about' element={<EditAboutPage/>}></Route>
          </Route>
        </Routes>
      </AnimatePresence>
    </AuthProvider>
  );
}

export default App;