import '../../styles/admin/Sidebar.css';
import React, { useState, useRef, useEffect } from 'react';
import img from '../../assets/logo.png';
import { MdHome, MdArrowRight, MdArrowDropDown, MdStar, MdNewspaper, MdEdit, MdList, MdNoteAdd, MdBook, MdNotificationAdd, MdGroupAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

function Sidebar() {
  const [openIndex, setOpenIndex] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleItemClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <div className="togglebtn">
        <div className="btn" onClick={toggleSidebar}><MdList size={30}/></div>
        {/* <div className="logo"><img src={img} alt="logo" /></div> */}
      </div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="logo"><img src={img} alt="logo" /></div>
        <div className="links">
          <div className="listitem">
            <Link to='/admin' className='nolistitems'>
              <div className='listitemhead' >
                <div className='icon'><MdHome/></div>
                <div className='title'>Dash Board</div>
                <div className="arrow"></div>
              </div>
            </Link>
          </div>
          <ListItem 
            title='Banner' 
            icon={<MdBook/>}
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 6}
            onClick={() => handleItemClick(6)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-banner'/>
              <ListItem title="Manage" path='/admin/view-banner'/>
            </div>
          </ListItem>
          <ListItem 
            title='Course' 
            icon={<MdBook/>}
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 0}
            onClick={() => handleItemClick(0)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-course'/>
              <ListItem title="Manage" path='/admin/view-courses'/>
            </div>
          </ListItem>
          <ListItem 
            title='Team' 
            icon={<MdGroupAdd/>}
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 3}
            onClick={() => handleItemClick(3)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-team-member'/>
              <ListItem title="Manage" path='/admin/view-team'/>
            </div>
          </ListItem>
          <ListItem 
            title='Testimonial' 
            icon={<MdStar/>} 
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 1}
            onClick={() => handleItemClick(1)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-testimonial'/>
              <ListItem title="Manage" path='/admin/view-testimonials'/>
            </div>
          </ListItem>
          <ListItem 
            title='News' 
            icon={<MdNewspaper/>} 
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 2}
            onClick={() => handleItemClick(2)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-news'/>
              <ListItem title="Manage" path='/admin/view-news'/>
            </div>
          </ListItem>
          <ListItem 
            title='Result' 
            icon={<MdNoteAdd/>}
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 4}
            onClick={() => handleItemClick(4)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-result'/>
              <ListItem title="Manage" path='/admin/view-result'/>
            </div>
          </ListItem>
          <ListItem 
            title='Notifications' 
            icon={<MdNotificationAdd/>}
            arrow={<MdArrowRight/>}
            isOpen={openIndex === 5}
            onClick={() => handleItemClick(5)}
          >
            <div>
              <ListItem title="Add" path='/admin/add-notification'/>
              <ListItem title="Manage" path='/admin/view-notifications'/>
            </div>
          </ListItem>
          <div className="listitem" onClick={() => handleItemClick(10)}>
            <Link to='/admin/edit-contacts' className='nolistitems'>
              <div className='listitemhead' >
                <div className='icon'><MdEdit/></div>
                <div className='title'>Contacts</div>
                <div className="arrow"></div>
              </div>
            </Link>
          </div>
          <div className="listitem" onClick={() => handleItemClick(10)}>
            <Link to='/admin/edit-counts' className='nolistitems'>
              <div className='listitemhead' >
                <div className='icon'><MdEdit/></div>
                <div className='title'>Counts</div>
                <div className="arrow"></div>
              </div>
            </Link>
          </div>
          <div className="listitem" onClick={() => handleItemClick(10)}>
            <Link to='/admin/edit-about' className='nolistitems'>
              <div className='listitemhead' >
                <div className='icon'><MdEdit/></div>
                <div className='title'>About</div>
                <div className="arrow"></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

const ListItem = ({ title, children, icon, arrow, isOpen, onClick, path }) => {
  const childrenRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  useEffect(() => {
    setMaxHeight(isOpen ? `${childrenRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  return (
    <Link to={path} className='listitem'>
      <div onClick={onClick}>
        <ListItemHead title={title} icon={icon} arrow={isOpen ? <MdArrowDropDown/> : arrow}/>
      </div>
      <div
        className={`children ${isOpen ? 'open' : ''}`}
        style={{ maxHeight }}
        ref={childrenRef}
      >
        {children}
      </div>
    </Link>
  );
};

const ListItemHead = ({ title, icon, arrow }) => {
  return (
    <div className='listitemhead'>
      <div className='icon'>{icon}</div>
      <div className='title'>{title}</div>
      <div className="arrow">{arrow}</div>
    </div>
  );
};

export default Sidebar;